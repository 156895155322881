import React from "react"
import SEO from "../components/seo"
// import { Helmet } from 'react-helmet'
import Layout from "../components/layout"
import FreeTrial from "../components/free-trial"
import TwoColumn from "../components/two-column"
import ComparisonTable from "../components/comparison-table"
import Testimonial from "../components/testimonial"
import SwiperTabs from "../components/swiper-tabs"
import PlanAction from "../components/plan-action"
import CustomerJourney from "../components/customer-journey"
// import GetStarted from "../components/get-started"
import {
  comparisonTableContent,
  customerJourneyContent,
  developersContent,
  // getStartedContent,
} from "../lib/home-content"
import { swiperTabsContent } from "../lib/swiper-tabs-content"
// import { testimonialContent } from "../lib/testimonial-content"
import withLocation from "../components/withLocation"

const powered = ({ search }) => {
  const { companyname } = search

  const bannerContent = {
    title: (
      <>
        Here's why companies like {companyname ? companyname : "yours"} rely on
        Billsby for easy and accurate recurring payments.{" "}
      </>
    ),
    description: [
      {
        text:
          "The most powerful, customizable and easy to integrate subscription billing software used by hundreds of companies worldwide to simplify revenue operations.",
      },
    ],
    withPromotion: true,
    promotionBadge: "OFFER",
    // promotionTitle:
    //   "Start building your subscription business with a $5,000 free trial of Billsby when you sign up today",
    textColor: "#fff",
    backgroundColor: "#243F5C",
    // backgroundImage: require("../images/home-hero.png"),
    // image: "home-hero.png",
    // srcSet: "home-hero@2x.png 2x",
    svg: true,
    image: require("../images/home-hero.svg"),
    imageName: "billsby banner image",
    imagePosition: "right",
    sectionHomeBanner: true,
    hasBg: true,
    isBanner: true,
    buttons: [
      {
        url: "https://app.billsby.com/registration",
        buttonText: "Sign up today",
        buttonColor: "orange",
        isExternal: true,
      },
      {
        scheduleButton: true,
      },
    ],
  }

  const swiperTitle = {
    title: <>{companyname ? companyname : "You"} dreamt it, we bill it.</>,
  }

  const planActionContent = {
    title: (
      <>
        {companyname
          ? companyname + " use Zapier to keep customers connected"
          : "Power your subscriptions with Zapier."}
      </>
    ),
    description:
      "Once customers sign-up and as their subscription changes, use Zapier to complete follow up tasks across all of the other tools and services you use to run your business.",
    image: require("../images/plan-action.svg"),
    svg: true,
    imageName: "plan action image",
    cards: [
      {
        image: "plan-action-1.png",
        srcSet: "plan-action-1@2x.png 2x",
        text: "Add the new customer to your CRM system",
        logo: require("../images/pipedrive.svg"),
        svgLogo: true,
        logoSrcSet: "",
        logoName: "pipedrive",
      },
      {
        image: "plan-action-2.png",
        srcSet: "plan-action-2@2x.png 2x",
        text: "Subscribe them to the correct mailing list",
        logo: "mailchimp.png",
        logoSrcSet: "mailchimp@2x.png 2x",
        logoName: "mailchimp",
      },
      {
        image: "plan-action-3.png",
        srcSet: "plan-action-3@2x.png 2x",
        text: "Post out their welcome pack",
        logo: "shipwire.png",
        logoSrcSet: "shipwire@2x.png 2x",
        logoName: "shipwire",
      },
    ],
    url: "/product/integrations/zapier",
    buttonColor: "blue",
    buttonText: "Learn about Zapier",
    comingSoonText: "",
  }

  const trialContent = {
    title: (
      <>
        {companyname
          ? companyname + " started with Billsby today. Now you can too."
          : "Get started with Billsby today" }
      </>
    ),
    description:
      <>You can sign up to Billsby for as little as $35 per month, including $15,000 per month in transaction value. <br /> The best way to get to know Billsby is to try it for yourself.</>,
    svg: true,
    image: require("../images/free-trial.svg"),
    srcSet: "free-trial@2x.png 2x",
    imageName: "free-trial image",
    buttons: [
      {
        url: "https://app.billsby.com/registration",
        buttonColor: "orange",
        buttonText: "Get started with Billsby today",
        isExternal: true,
      },
      {
        scheduleButton: true,
        buttonColor: "black",
      },
    ],
  }

  return (
    <Layout isPoweredPage isHeaderTransparent className="powered-page">
      <SEO 
        title={`${
          companyname
            ? companyname
            : "Billsby | Subscription billing software for recurring payments"
        }`}
        description="Rely on Billsby for easy and accurate recurring payments. The most powerful, customizable and easy-to-integrate subscription billing software." 
        url="https://www.billsby.com/powered`"
      />

      <TwoColumn content={bannerContent} />
      <SwiperTabs
        tabs={swiperTabsContent}
        title={swiperTitle.title}
        description="Every Billsby account supports unlimited products, plans and cycles - making it really easy to support even the most complex plans."
      />
      <PlanAction content={planActionContent} />
      <CustomerJourney content={customerJourneyContent} />
      <ComparisonTable content={comparisonTableContent} />
      <TwoColumn content={developersContent} />
      {/* <GetStarted content={getStartedContent} /> */}
      <Testimonial />
      <FreeTrial content={trialContent} />
    </Layout>
  )
}

export default withLocation(powered)
